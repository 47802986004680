<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img :src="app.appLogoImage" alt="logo" />
        <h2 class="brand-text text-primary ml-1">
          {{ app.appName }}<sup class="brand-trademark">&reg;</sup>
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" class="w-50" alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Adventure starts here 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            Make your app management easy and fun!
          </b-card-text>

          <!-- form -->
          <validation-observer v-slot="{handleSubmit}" ref="registerForm">
            <b-form class="auth-register-form mt-2" role="form" @submit.stop.prevent="handleSubmit(register)">
              <!-- username -->
              <b-form-group label="Username" label-for="register-username">
                <validation-provider #default="{ errors }" name="Username" rules="required">
                  <b-form-input
                    id="register-username"
                    v-model="account.username"
                    name="register-username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="account.email"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="account.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link>privacy policy & terms</b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button type="submit" block variant="primary" :disabled="state.loading">
                <b-icon v-if="state.loading" icon="arrow-clockwise" animation="spin" class="mx-2"></b-icon> Sign up
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>

        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { adminAddUserToGroup } from '@/scripts/aws'
import Avatar from '@/models/avatar'
import {createUser} from '@/graphql/mutations'
import {$themeConfig} from '@themeConfig';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      app: $themeConfig.app,
      account: {
        username: null,
        email: null,
        password: null
      },
      state: {
        loading: false,
        error: null
      },
      status: '',
      username: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/illustration/Orchestra-rafiki_solo.svg'),
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async register() {
      try {
        this.state.loading = true;
        this.state.error = null

        await Auth.signUp({ username: this.account.username, password: this.account.password, attributes: { email: this.account.email } }).then(async (response) => {
          try {
            await adminAddUserToGroup(response.user.getUsername(), 'User');
            await API.graphql({
              authMode: 'AWS_IAM',
              query: createUser,
              variables: {
                input: {
                  id: response.userSub,
                  username: response.user.username,
                  avatar: new Avatar()
                }
              }
            })
            .then(() => {
              this.$router.push({ name: 'auth-login' })
            })
            .catch(e => {
              console.error(e)
            })
          }
          catch (error) {
            console.error(error)
            this.state.error = error.message;
          }
        });
      }
      catch (error) {
        this.state.error = error.message;
      }
      finally {
        this.state.loading = false;
      }
    },
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
